import { Component, ChangeDetectorRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';

@Component({
  selector: 'navbar360-root',
  templateUrl: './app.html',
})
export class AppComponent implements OnInit {
  constructor(
    public router: Router,
    public cd: ChangeDetectorRef,
    public ngZone: NgZone
  ) {  }

  public PRINCPAL_APP_NAME = environment.PRINCPAL_APP_NAME;

  ngOnInit() {
    //
  }
}

import { Component, OnInit,OnDestroy } from '@angular/core';
import { environment } from 'environments/environment';


@Component({
  selector: 'page-404',
  templateUrl: './page-404.html',
  styleUrls: ['./styles-404page.css'],
})

export class Pages404 implements OnInit {


  constructor() { }

  public SCHOOL_LOADER:any = environment.SCHOOL_LOADER;

  ngOnInit() {
  }



  
}








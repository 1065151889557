import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  OnDestroy,
} from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { AuthService } from "../../models/Auth/auth.service";
import { UsersService } from "../../models/usersInfo/users.service";
import { environment } from "environments/environment";
import { switchMap } from "rxjs/operators";
import { Subscription, forkJoin, of } from "rxjs";
import Swal from "sweetalert2/dist/sweetalert2.js";

@Component({
  selector: "profile-nav",
  templateUrl: "./profile-nav.component.html",
  styleUrls: ["./profile-nav.component.css"],
  providers: [UsersService],
})
export class ProfileNavComponent implements OnInit, OnDestroy {
  @Output("logOut") logOut: EventEmitter<any> = new EventEmitter();
  public nameSchools: string[] = [];
  public nameRoles: string[] = [];
  public showNav: boolean = false;
  public SCHOOL_LOADER: any = environment.SCHOOL_LOADER;
  public SCHOOL_NAME:string = environment.SCHOOL_NAME;
  private _subscription: Subscription = new Subscription();

  constructor(
    private _authService: AuthService,
    public _router: Router,
    private _servicios: UsersService
  ) {}
  ngOnInit() {
    this._subscription = this._router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) this.loadNav();
    });
  }

  private loadNav() {
    this.nameRoles = [];
    this.nameSchools = [];
    this.showNav = false;

    this._authService
      .getCurrentUser()
      .pipe(
        switchMap((user) => {
          if (!user) return of([]);

          return forkJoin({
            roles: this._servicios.getRolesByUserID(user.userId),
            user: this._servicios.get(user.userId),
          });
        })
      )
      .subscribe({
        next: ({ roles, user }: any) => {
          if (!roles || roles.length === 0 || !user) return;

          const school = roles[0].school;
          this.nameSchools.push(
            `${school.NameTSchool} ${school.SchoolAcronim}`
          );

          roles.forEach((item: any) => {
            const role = item.role;
            if (!this.nameRoles.includes(role.name))
              this.nameRoles.push(role.name);
          });

          this.validateFields(roles, user);
          this.showNav = true;
        },
        error: (err) => {
          console.log(err);
          this.showNav = false;
        },
      });
  }

  private validateFields(roles: any[], user: any) {
    const { ContactInfos, WorkInfos, AcademicInfos/* , MilitarInfos, TypeUser  */} = user;
    const isCedocStudent = this.SCHOOL_NAME === "CEDOC" && roles.some((e) => [10, 2].includes(e.roleId));
    const isCelicParent = this.SCHOOL_NAME === "CELIC" && roles.some((e) => e.roleId === 29);

    const isCedocValid = isCedocStudent ?
/*       (TypeUser && TypeUser.isMilitar 
        ? (MilitarInfos.length > 0 && MilitarInfos[0].realRangeID) 
        : true) && */
      ContactInfos.length > 0 &&
      ContactInfos[0].Address &&
      ContactInfos[0].Phone &&
      ContactInfos[0].CellPhone &&
      ContactInfos[0].birthDate &&
      ContactInfos[0].Nacionality : true;

    const isCelicValid = isCelicParent ?
 /*      (TypeUser && TypeUser.isMilitar 
        ? (MilitarInfos.length > 0 && MilitarInfos[0].realRangeID) 
        : true) && */
      ContactInfos &&
      ContactInfos.length > 0 &&
      ContactInfos[0].birthDate &&
      ContactInfos[0].Address &&
      ContactInfos[0].Nacionality &&
      ContactInfos[0].Phone &&
      ContactInfos[0].CellPhone &&
      AcademicInfos &&
      AcademicInfos.length > 0 &&
      WorkInfos &&
      WorkInfos.length > 0 : true;

    if (this.checkCommonURLConditions()) {
      if (!isCedocValid || !isCelicValid) {
        this.warningAlert(
          "Datos incompletos",
          "Aún hay campos pendientes por completar en tu perfil.",
          "Actualizar perfil"
        );
        return;
      }
    }
    this.changePasswordAlert(roles, user);
  }

  private changePasswordAlert(roles: any[], user: any) {
    const allowedRoles = [29, 10];
    const canChangePassword = roles.some(
      (e) => allowedRoles.includes(e.roleId) && user.pendingChangePass
    );

    if (canChangePassword && this.checkCommonURLConditions()) {
      this.warningAlert(
        "Seguridad de la cuenta",
        "Por favor, cambie su contraseña por motivos de seguridad.",
        "Cambiar contraseña"
      );
    }
  }

  private checkCommonURLConditions(): boolean {
    const commonURLs = ["/Cv-Public/me", "/landing", "/register"];
    return !commonURLs.includes(this._router.url);
  }

  private warningAlert(title: string, text: string, buttonText: string) {
    Swal({
      type: "warning",
      title: title,
      text: text,
      showConfirmButton: true,
      confirmButtonText: buttonText,
      allowEscapeKey: false,
      allowOutsideClick: false,
    }).then((result) => {
      if (result.value) 
        this._router.navigate(["/Cv-Public/me"]);
    });
  }

  public logout() {
    this._router.navigate(["/register"]);
    this._servicios.logout().subscribe((response) => {
      this.logOut.emit();
    });
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }
}
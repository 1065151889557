import { Component, OnInit, Input } from '@angular/core';
import { TopBarComponent } from '../top-bar.component';
import { environment } from 'environments/environment';


@Component({
  selector: 'app-chatbox',
  templateUrl: './chatbox.component.html',
  styleUrls: ['./chatbox.component.css']
})

export class ChatboxComponent implements OnInit {


  @Input() conversations: any = [];


  constructor(public chat: TopBarComponent) { }

  public SARA_FEELING_DEFAULT: any = environment.SARA_FEELING_DEFAULT;
  public SARA_FEELING_DYNAMIC :any = (feel:any) => environment.SARA_FEELING_DYNAMIC(feel);

  ngOnInit() {}

}

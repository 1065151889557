import { ClassificationUser } from './classification-user';
import { OnInit } from '@angular/core';
import { Injectable } from '@angular/core';

import { UsersField } from './users-fields';
import { Force } from './force';
import { Range } from './ranges';
import { Role } from './role';
import { TypeDocument } from './typedocument';
import { AuthService } from "../Auth/auth.service";

import { UserJob } from './userjob';
import { UserCourse } from './usercourse';
import { ProfesorCv } from './profesor-cv';
import { ProfesionalPerfil } from './profesionalperfil';
import { Inscription } from './inscription';
import { SubjectGroup } from './subjectgroup';
import { TypeCourse } from '../Coursesinfo/typecourse';
import { UserDocument } from './userDocument';
import { isNullOrUndefined } from 'util';
import { UserCourseCert } from './user-course-cert';
import { TypeCert } from './type-cert';
import { StudentRecord } from './student-record';
import { HealthInfoInterface } from './healthInfoInteface';
import { RoleMapping } from './rolemapping';
import { catchError, map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';


@Injectable()
export class UsersService {
  public roles: Role[];

  constructor(private http: HttpClient, private authService: AuthService) {}

  private baseUrl: string = this.authService.UrlAPI;

  getHeaders(): HttpHeaders {
    let headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: this.authService.getToken(),
    });
    return headers;
  }

  putHeaders(): HttpHeaders {
    let headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: this.authService.getToken(),
    });
    return headers;
  }

  getAll(): Observable<any> {
    let users$ = this.http
      .get(`${this.baseUrl}/userapps`, { headers: this.getHeaders() })
      .pipe(catchError((err) => this.authService.errorMessages(err)));

    return users$;
  }

  getRolesByUserID(id: number): Observable<any> {
    let role$ = this.http
      .get(
        `${this.baseUrl}/Userapps/${id}/roleMappings?filter={"include": ["school","role"]}`,
        { headers: this.getHeaders() }
      )
      .pipe(catchError((err) => this.authService.errorMessages(err)));
    return role$;
  }
  getRolesByUserIDAndSchoolID(
    userID: number,
    SchoolID: number
  ): Observable<any> {
    let role$ = this.http
      .get(
        `${this.baseUrl}/Schools/${SchoolID}/roleMappings?filter={"where": {"principalId":${userID}}}`,
        { headers: this.getHeaders() }
      )
      .pipe(catchError((err) => this.authService.errorMessages(err)));
    return role$;
  }
  getAllRoles(): Observable<any> {
    let role$ = this.http
      .get(`${this.baseUrl}/roles`, { headers: this.getHeaders() })
      .pipe(catchError((err) => this.authService.errorMessages(err)));
    return role$;
  }

  deleteRoleMappingID(id): Observable<RoleMapping> {
    return this.http
      .delete(`${this.baseUrl}/roleMappings/${id}`, {
        headers: this.putHeaders(),
      })
      .pipe(catchError((err) => this.authService.errorMessages(err)));
  }

  newRoleMappginToUserID(id, data): Observable<RoleMapping> {
    return this.http
      .post(`${this.baseUrl}/roleMappings`, JSON.stringify(data), {
        headers: this.putHeaders(),
      })
      .pipe(catchError((err) => this.authService.errorMessages(err)));
  }

  getCvProfesorID(id: string): Observable<ProfesorCv> {
    let profesor$ = this.http
      .get(
        `${this.baseUrl}/Userapps/findOne?filter={"where": {"and": [{"CedocEmail":"${id}"}]},"include": ["ScientificInfos","AcademicInfos","WorkInfos", "ContactInfos"]}`,
        { headers: this.getHeaders() }
      )
      .pipe(catchError((err) => this.authService.errorMessages(err)));

    return profesor$;
  }

  getStudentsByCourseID(id: number): Observable<any> {
    let users$ = this.http
      .get(
        `${this.baseUrl}/CoursesOfers/${id}/userapps?filter={"include": ["MilitarInfos","UserDocuments"]}`,
        { headers: this.getHeaders() }
      )
      .pipe(catchError((err) => this.authService.errorMessages(err)));

    return users$;
  }

  getStudentsByCourseID2(id: number): Observable<any> {
    let users$ = this.http
      .get(
        `${this.baseUrl}/CoursesOfers/${id}/userapps?filter={"include": ["MilitarInfos","UserDocuments"]}`,
        { headers: this.getHeaders() }
      )
      .pipe(
        map(mapPersons),
        catchError((err) => this.authService.errorMessages(err))
      );

    return users$;
  }

  UpsertProfileByID(
    profile: ProfesionalPerfil,
    id: number
  ): Observable<ProfesionalPerfil> {
    let responsePost = this.http
      .put(
        `${this.baseUrl}/Userapps/${id}/ProfesionalProfile/${profile.id}`,
        JSON.stringify(profile),
        { headers: this.putHeaders() }
      )
      .pipe(catchError((err) => this.authService.errorMessages(err)));

    return responsePost;
  }

  GetProfileByUserID(id: number): Observable<any> {
    let responsePost$ = this.http
      .get(`${this.baseUrl}/Userapps/${id}/ProfesionalProfile`, {
        headers: this.putHeaders(),
      })
      .pipe(catchError((err) => this.authService.errorMessages(err)));

    return responsePost$;
  }

  getUserDocument(id: number): Observable<UserDocument> {
    return this.http
      .get(
        `${this.baseUrl}/UserDocuments?filter={"where": {"UserID": ${id}}}`,
        { headers: this.putHeaders() }
      )
      .pipe(catchError((err) => this.authService.errorMessages(err)));
  }

  getUserProfesionalProfile(id: number): Observable<ProfesionalPerfil> {
    return this.http
      .get(
        `${this.baseUrl}/ProfesionalProfiles?filter={"where": {"UserID": ${id}}}`,
        { headers: this.putHeaders() }
      )
      .pipe(catchError((err) => this.authService.errorMessages(err)));
  }

  pachUserCourseCert(data): Observable<UserCourseCert> {
    return this.http
      .patch(
        `${this.baseUrl}/UserCourseCerts `,
        JSON.stringify(data),

        { headers: this.putHeaders() }
      )
      .pipe(catchError((err) => this.authService.errorMessages(err)));
  }

  pachUserDocument(data): Observable<UserDocument> {
    return this.http
      .patch(
        `${this.baseUrl}/UserDocuments `,
        JSON.stringify(data),

        { headers: this.putHeaders() }
      )
      .pipe(catchError((err) => this.authService.errorMessages(err)));
  }

  pachUserDocumentByID(id: number, data): Observable<UserDocument> {
    return this.http
      .patch(
        `${this.baseUrl}/UserDocuments/${id} `,
        JSON.stringify(data),

        { headers: this.putHeaders() }
      )
      .pipe(catchError((err) => this.authService.errorMessages(err)));
  }

  postUserDocument(data): Observable<UserDocument> {
    return this.http
      .post(
        `${this.baseUrl}/UserDocuments `,
        JSON.stringify(data),

        { headers: this.putHeaders() }
      )
      .pipe(catchError((err) => this.authService.errorMessages(err)));
  }

  postProfesionalProfileByUserID(userID: number, data): Observable<any> {
    return this.http
      .post(
        `${this.baseUrl}/Userapps/${userID}/ProfesionalProfile`,
        JSON.stringify(data),

        { headers: this.putHeaders() }
      )
      .pipe(catchError((err) => this.authService.errorMessages(err)));
  }

  pachProfesionalProfileByID(id: number, data): Observable<any> {
    return this.http
      .patch(
        `${this.baseUrl}/ProfesionalProfiles/${id} `,
        JSON.stringify(data),

        { headers: this.putHeaders() }
      )
      .pipe(catchError((err) => this.authService.errorMessages(err)));
  }

  getInscrptionByUserID(UserID: number): Observable<any> {
    let inscriptions$ = this.http
      .get(
        `${this.baseUrl}/Userapps/${UserID}/Inscriptions/?filter={"order":"SchoolID asc"}`,
        { headers: this.putHeaders() }
      )
      .pipe(catchError((err) => this.authService.errorMessages(err)));
    return inscriptions$;
  }

  getOAPStudentsByCourseID(id: number): Observable<any> {
    let users$ = this.http
      .get(
        `${this.baseUrl}/OAPs?filter={"where": {"and": [{"CourseOferID":${id}}]}}`,
        { headers: this.getHeaders() }
      )
      .pipe(catchError((err) => this.authService.errorMessages(err)));

    return users$;
  }

  getRange(forceID: number, OtanID: number): Observable<any> {
    let range_name = this.http
      .get(
        `${this.baseUrl}/Ranges/?filter={"where": {"and": [{"ForceID":${forceID}} ,{"OtanID":${OtanID}} ]}}`,
        { headers: this.getHeaders() }
      )
      .pipe(catchError((err) => this.authService.errorMessages(err)));

    return range_name;
  }

  getAllForces(): Observable<any> {
    let forces$ = this.http
      .get(`${this.baseUrl}/Forces`, { headers: this.getHeaders() })
      .pipe(catchError((err) => this.authService.errorMessages(err)));
    return forces$;
  }

  getForceByID(id: number): Observable<any> {
    let typeDocuments$ = this.http
      .get(`${this.baseUrl}/Forces/${id}`, { headers: this.getHeaders() })
      .pipe(catchError((err) => this.authService.errorMessages(err)));
    return typeDocuments$;
  }

  getAllTypeDocuments(): Observable<any> {
    let typeDocuments$ = this.http
      .get(`${this.baseUrl}/TypeDocuments`, { headers: this.getHeaders() })
      .pipe(catchError((err) => this.authService.errorMessages(err)));
    return typeDocuments$;
  }

  getAllTypeDocumentsByID(id: number): Observable<any> {
    let typeDocuments$ = this.http
      .get(`${this.baseUrl}/TypeDocuments/${id}`, {
        headers: this.getHeaders(),
      })
      //.get(`${this.baseUrl}/TypeDocuments/?filter={"where": {"and": [{"id": ${id}}  ]}}`, { headers: this.getHeaders()})
      .pipe(catchError((err) => this.authService.errorMessages(err)));
    return typeDocuments$;
  }

  getAllRangesByForce(id: number): Observable<any> {
    if (id == 0) {
      id = 7;
    }

    let forces$ = this.http
      .get(`${this.baseUrl}/Forces/${id}/Ranges`, {
        headers: this.getHeaders(),
      })
      .pipe(catchError((err) => this.authService.errorMessages(err)));
    return forces$;
  }

  get(id: number): Observable<UsersField> {
    const filters = JSON.stringify({
      include: ["MilitarInfos", "TypeUser", "ContactInfos", "UserDocuments", "ProfesionalProfile", 'ClassificationUser', 'WorkInfos', 'AcademicInfos'],
    });

    const person = this.http
      .get(`${this.baseUrl}/Userapps/${id}/?filter=${filters}`, {
        headers: this.getHeaders(),
      })
      .pipe(catchError((err) => this.authService.errorMessages(err)));
    return person;
  }

  getHealthInfoByUserID(id: number): Observable<any> {
    let person$ = this.http
      .get(`${this.baseUrl}/Userapps/${id}/healthInfos`, {
        headers: this.getHeaders(),
      })
      .pipe(catchError((err) => this.authService.errorMessages(err)));
    return person$;
  }

  get2(id: number): Observable<UsersField> {
    return this.http.get(`${this.baseUrl}/Userapps/${id}/`, {
      headers: this.getHeaders(),
    });
  }

  save(person: UsersField): Observable<any> {
    return this.http
      .put(`${this.baseUrl}/userapps/${person.id}`, JSON.stringify(person), {
        headers: this.putHeaders(),
      })
      .pipe(catchError((err) => this.authService.errorMessages(err)));
  }
  getUsersByEmailAndSchoolID(userEmail: string): Observable<any> {
    return this.http
      .get(
        `${this.baseUrl}/Userapps?filter={"where": {"and": [{"CedocEmail": { "like" : "%25${userEmail}%25"}}]},"limit": 5}`,
        { headers: this.putHeaders() }
      )
      .pipe(catchError((err) => this.authService.errorMessages(err)));
  }

  DeleteUserByID(UserID): Observable<any> {
    return this.http
      .delete(`${this.baseUrl}/Userapps/${UserID}`, {
        headers: this.putHeaders(),
      })
      .pipe(catchError((err) => this.authService.errorMessages(err)));
  }

  updateInfoPerson(person: UsersField): Observable<UsersField> {
    console.debug(person);

    let updateperson = this.http
      .put(
        `${this.baseUrl}/Userapps/${person.UserID}/personalInfo`,
        JSON.stringify(person),
        { headers: this.putHeaders() }
      )
      .pipe(catchError((err) => this.authService.errorMessages(err)));
    return updateperson;
  }

  updateNames(person: UsersField): Observable<UsersField> {
    console.debug(person);

    let updateperson = this.http
      .post(
        `${this.baseUrl}/Userapps/${person.UserID}/updateNames`,
        JSON.stringify(person),
        { headers: this.putHeaders() }
      )
      .pipe(catchError((err) => this.authService.errorMessages(err)));
    return updateperson;
  }

  createStudent(person: UsersField): Observable<UsersField> {
    person.CedocEmail = getCleanedString(
      `${person.Name1}${person.LastName1}${person.LastName2}`
    );
    person.id = null;
    console.debug(person);

    let responsePost = this.http
      .post(`${this.baseUrl}/userapps/student`, JSON.stringify(person), {
        headers: this.putHeaders(),
      })
      .pipe(catchError((err) => this.authService.errorMessages(err)));
    return responsePost;
  }

  assignCourseOfer(courseOfer: UserCourse): Observable<any> {
    let responsePost = this.http
      .post(
        `${this.baseUrl}/userapps/${courseOfer.UserID}/assignUserCourse`,
        JSON.stringify(courseOfer),
        { headers: this.putHeaders() }
      )
      .pipe(catchError((err) => this.authService.errorMessages(err)));
    return responsePost;
  }

  getUserCourseCerts(UserCourseID: number): Observable<any> {
    return this.http
      .get(
        `${this.baseUrl}/UserCourseCerts?filter={"where": {"UserCourseID": ${UserCourseID}}}`,
        { headers: this.putHeaders() }
      )
      .pipe(catchError((err) => this.authService.errorMessages(err)));
  }

  getAllUserCourseCerts(idSchool: number, year: number): Observable<any> {
    var filter = `?filter={"where": {"SchoolID": ${idSchool}, "timeStamp": {"between": ["${year}-01-01","${year}-12-31"]}}, 
    "include": ["userapp", "typeCert", "school",

                { "relation":  "userCourse", "scope": { "include": ["coursesOfer"] } }

                ]
  }
    `;
    return this.http
      .get(`${this.baseUrl}/UserCourseCerts${filter}`, {
        headers: this.putHeaders(),
      })
      .pipe(catchError((err) => this.authService.errorMessages(err)));
  }

  getTypeCerts(SchoolID: number): Observable<TypeCert> {
    return this.http
      .get(
        `${this.baseUrl}/TypeCerts?filter={"where":{"SchoolID": ${SchoolID}}}`,
        { headers: this.putHeaders() }
      )
      .pipe(catchError((err) => this.authService.errorMessages(err)));
  }

  getStudentRecordsByUserIDAndUCourseOferID(
    CourseOferID: number,
    UserID: number
  ): Observable<TypeCert> {
    return this.http
      .get(
        `${this.baseUrl}/StudentRecords?filter={"where": {"and": [{"CourseOferID": ${CourseOferID}},{"UserID":${UserID}}]}}`,
        { headers: this.putHeaders() }
      )
      .pipe(catchError((err) => this.authService.errorMessages(err)));
  }

  sendCertRequest(model: UserCourseCert): Observable<any> {
    let response = this.http
      .post(`${this.baseUrl}/UserCourseCerts`, JSON.stringify(model), {
        headers: this.putHeaders(),
      })
      .pipe(catchError((err) => this.authService.errorMessages(err)));
    return response;
  }

  sendCertAprove(model: UserCourseCert): Observable<any> {
    let response = this.http
      .post(`${this.baseUrl}/UserCourseCerts/pdf`, JSON.stringify(model), {
        headers: this.putHeaders(),
      })
      .pipe(catchError((err) => this.authService.errorMessages(err)));
    return response;
  }

  createInstructor(person: UsersField): Observable<UsersField> {
    person.CedocEmail = getCleanedString(
      `${person.Name1}${person.LastName1}${person.LastName2}`
    );
    person.id = null;
    console.debug(person);

    let responsePost = this.http
      .post(`${this.baseUrl}/userapps/instructor`, JSON.stringify(person), {
        headers: this.putHeaders(),
      })
      .pipe(catchError((err) => this.authService.errorMessages(err)));
    return responsePost;
  }

  assignAcademicArea(person: UserJob): Observable<any> {
    let responsePost = this.http
      .post(
        `${this.baseUrl}/userapps/${person.UserID}/assignUserJob`,
        JSON.stringify(person),
        { headers: this.putHeaders() }
      )
      .pipe(catchError((err) => this.authService.errorMessages(err)));
    return responsePost;
  }

  login(email: string, password: string): Observable<any> {
    let url = this.baseUrl + "/Userapps/login";
    return this.http
      .post(
        url,
        { email: email, password: password },
        { headers: this.getHeaders() }
      )
      .pipe(
        catchError((err) => {
          console.log("Handling error locally and rethrowing it...", err);
          return throwError(err);
        })
      );
  }

  logout(): Observable<any> {
    let responsePost = this.http
      .post(
        `${
          this.baseUrl
        }/Userapps/logout?access_token=${this.authService.getToken()}`,
        { access_token: this.authService.getToken() },
        { headers: this.putHeaders() }
      )
      .pipe(catchError((err) => this.authService.errorMessages(err)));
    return responsePost;
  }
}


function getCleanedString(cadena) {
  // Definimos los caracteres que queremos eliminar
  var specialChars = "!@#$^&%*()+=-[]\/{}|:<>?,.";

  // Los eliminamos todos
  for (var i = 0; i < specialChars.length; i++) {

    cadena = cadena.replace(new RegExp("\\" + specialChars[i], 'gi'), '');

  }

  // Lo queremos devolver limpio en minusculas
  cadena = cadena.toLowerCase();

  // Quitamos espacios y los sustituimos por _ porque nos gusta mas asi
  cadena = cadena.replace(/ /g, "");
  cadena = cadena.replace(/undefined/g, "");
  cadena = cadena.replace(/null/g, "");

  // Quitamos acentos y "ñ". Fijate en que va sin comillas el primer parametro
  cadena = cadena.replace(/á/gi, "a");
  cadena = cadena.replace(/é/gi, "e");
  cadena = cadena.replace(/í/gi, "i");
  cadena = cadena.replace(/ó/gi, "o");
  cadena = cadena.replace(/ú/gi, "u");
  cadena = cadena.replace(/ü/gi, "u");
  cadena = cadena.replace(/ñ/gi, "n");

  return cadena;

}

function mapPersons(response): UsersField[] {
  //throw new Error('ups! Force choke!');
  // The response of the API has a results
  // property with the actual results
  return response.map(toPerson)

}

function toPerson(r: any): UsersField {

  let cedulaMil = null;
  let document = null;
  let description = null;

  if (r.MilitarInfos[0])
    cedulaMil = r.MilitarInfos[0].CedulaMil;

  if (r.UserDocuments[0])
    document = r.UserDocuments[0].Document;

  if (r.ProfesionalProfile[0])
    description = r.ProfesionalProfile[0].Description;

  let person = <UsersField>({

    id: r.id,
    CedocEmail: r.CedocEmail,
    Name1: r.Name1,
    Name2: r.Name2,
    LastName1: r.LastName1,
    LastName2: r.LastName2,

    Description: description,
    //CellPhone:r.CellPhone,
    // Email:r.Email,

    Names: r.Name1 + " " + r.Name2,
    LastNames: r.LastName1 + " " + r.LastName2,


    Document: document,

    IsMilitar: r.IsMilitar,
    CedulaMil: cedulaMil,
    IsTemporal: r.IsTemporal

  });

  return person;

}


function mapRoles(response): Role[] {

  return response.map(toRole)

}


function toRole(r: any): Role {

  let role = <Role>({

    id: r.role.id,
    name: r.role.name,
    SchoolID: r.SchoolID,
    NameTSchool: r.school.NameTSchool,
    SchoolAcronim: r.school.SchoolAcronim

  });

  return role;

}




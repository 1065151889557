import { Component, Inject, OnDestroy, OnInit } from '@angular/core';

import { AuthService } from "../../models/Auth/auth.service";
import { UsersService } from "../../models/usersInfo/users.service";
import { environment } from 'environments/environment';
import { NavigationEnd, Router } from '@angular/router';
import { map, switchMap } from 'rxjs/operators';
import { Subscription, forkJoin, of } from 'rxjs';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'cat-menu-left',
  templateUrl: './menu-left-vertical.component.html',
  providers: [UsersService],
})

export class MenuLeftComponent implements OnInit, OnDestroy {
  public isSubmenu = false;
  public isSubmenu2 = false;
  public isAdmin: boolean = false;
  public isGroup: boolean = false;
  public isSchedule: boolean = false;
  public isStudent: boolean = false;
  public isInstructor: boolean = false;
  public isAttendant: boolean = false;
  public isWellness: boolean = false;
  public isExtensionGraduates: boolean = false;
  public isLibrary: boolean = false;
  public isInspector: boolean = false;
  public isDirector: boolean = false;
  public isSuper: boolean = false;
  public isGeneral: boolean = false;
  public isQuality: boolean = false;
  public isInformation: boolean = false;
  public isCandidate: boolean = false;
  public isResearch: boolean = false;
  public isMajor: boolean = false;
  public isRegister: boolean = false;
  public isBoss: boolean = false;
  public isContracting: boolean = false;
  public isFinancial: boolean = false;
  public isRequest: boolean = false;
  public isFinancieraAdmin:boolean = false;

  public schoolGeneral: number = 0
  private _roleMappings = {
    "Información": "isInformation",
    "Inspector Escuela": "isInspector",
    "admin": "isAdmin",
    "Docente": "isInstructor",
    "Acudiente": "isAttendant",
    "Bienestar": "isWellness",
    "Extensión Egresado": "isExtensionGraduates",
    "Biblioteca": "isLibrary",
    "Investigación": "isResearch",
    "Estudiante": "isStudent",
    "Aspirante": "isCandidate",
    "Personal": "isSuper",
    "Horarios": "isSchedule",
    "Agrupacion": "isGroup",
    "General": "isGeneral",
    "Calidad": "isQuality",
    "Director": "isDirector",
    "Comandante": "isMajor",
    "EstudianteDir": "isDirector",
    "Registro y Control": "isRegister",
    "Jefe": "isBoss",
    "Contratación": "isContracting",
    "Financiera": "isFinancial",
    "Solicitudes": "isRequest",
    "Administración Financiera": "isFinancieraAdmin"
  };

  public email: string = '';
  public MAIN_SCHOOL_WHITE_LOGO: any = environment.MAIN_SCHOOL_WHITE_LOGO;
  public SCHOOL_NAME: string = environment.SCHOOL_NAME;
  public showNav: boolean = false;

  private _subscription: Subscription = new Subscription();

  constructor(
    @Inject(DOCUMENT) private _document:Document,
    private _authService: AuthService,
    private _servicios: UsersService,
    private _router: Router
  ) { }

  ngOnInit() {
    this._subscription = this._router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const currentUser = parseInt(localStorage.getItem('currentUser'));
        this.loadRoles(currentUser);
      }
    });
  }

  private loadRoles(userId: number) {
    this.toggleNav(false);
    this._authService.getCurrentUser().pipe(
      switchMap(user => {
        if (!user)
          return of([])

        return forkJoin({
          roles: this._servicios.getRolesByUserID(user.userId),
          user: this._servicios.get(userId)
        }).pipe( map( e => e) )
      })
    ).subscribe({
      next: ({roles, user}:any) => {
        if(!roles || roles.length === 0 || !user)
          return;

        this.email = user.CedocEmail;
 
        //Assign the current user roles
        roles.forEach((roles:any) => {
          const role = roles.role;
          const propertyName = this._roleMappings[role.name];

          if (propertyName) {
            this[propertyName] = true;
            if (role.name === "General")
              this.schoolGeneral = role.SchoolID;
          }
        });

        this.LoadAnime();
        this.toggleNav(true);
      }, error: (err) => {
        this.toggleNav(false);
        console.log(err);
      }
    })
  }

  public toggleNav(showNav:boolean){
    const element:any = this._document.querySelector('#single-spa-application\\:cedoc360');
    this.showNav = showNav;

    if (element){
      if(showNav){
        element.classList.remove('hide__menu-left');
      }else{
        element.classList.add('hide__menu-left');
      }
    }
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  LoadAnime() {
    $(function () {

      // scripts for "menu-left" module

      /////////////////////////////////////////////////////////////////////////////////////////
      // add backdrop

      $('.cat__menu-left').after('<div class="cat__menu-left__backdrop cat__menu-left__action--backdrop-toggle"><!-- --></div>');

      /////////////////////////////////////////////////////////////////////////////////////////
      // submenu

      $('.cat__menu-left__submenu > a').on('click', function () {

        if ($('body').hasClass('cat__config--vertical') || $('body').width() < 768) {

          var parent = $(this).parent(),
            opened = $('.cat__menu-left__submenu--toggled');

          if (!parent.hasClass('cat__menu-left__submenu--toggled') && !parent.parent().closest('.cat__menu-left__submenu').length)
            opened.removeClass('cat__menu-left__submenu--toggled').find('> .cat__menu-left__list').slideUp(200);

          parent.toggleClass('cat__menu-left__submenu--toggled');
          parent.find('> .cat__menu-left__list').slideToggle(200);

        }

      });

      // remove submenu toggle class when viewport back to full view
      $(window).on('resize', function () {
        if ($('body').hasClass('cat__config--horizontal') || $('body').width() > 768) {
          $('.cat__menu-left__submenu--toggled').removeClass('cat__menu-left__submenu--toggled').find('> .cat__menu-left__list').attr('style', '');
        }
      });


      /////////////////////////////////////////////////////////////////////////////////////////
      // custom scroll init

      if ($('body').hasClass('cat__config--vertical')) {

      }


      /////////////////////////////////////////////////////////////////////////////////////////
      // toggle menu

      $('.cat__menu-left__action--menu-toggle').on('click', function () {
        if ($('body').width() < 768) {
          $('body').toggleClass('cat__menu-left--visible--mobile');
        } else {
          $('body').toggleClass('cat__menu-left--visible');
        }
      })

      $('.cat__menu-left__action--backdrop-toggle').on('click', function () {
        $('body').removeClass('cat__menu-left--visible--mobile');
      })


      /////////////////////////////////////////////////////////////////////////////////////////
      // colorful menu

      var colorfulClasses = 'cat__menu-left--colorful--primary cat__menu-left--colorful--secondary cat__menu-left--colorful--primary cat__menu-left--colorful--default cat__menu-left--colorful--info cat__menu-left--colorful--success cat__menu-left--colorful--warning cat__menu-left--colorful--danger cat__menu-left--colorful--yellow',
        colorfulClassesArray = colorfulClasses.split(' ');

      function setColorfulClasses() {
        $('.cat__menu-left__list--root > .cat__menu-left__item').each(function () {
          var randomClass = colorfulClassesArray[Math.floor(Math.random() * colorfulClassesArray.length)];
          $(this).addClass(randomClass);
        })
      }

      function removeColorfulClasses() {
        $('.cat__menu-left__list--root > .cat__menu-left__item').removeClass(colorfulClasses);
      }

      if ($('body').hasClass('cat__menu-left--colorful')) {
        setColorfulClasses();
      }

      $('body').on('setColorfulClasses', function () {
        setColorfulClasses();
      });

      $('body').on('removeColorfulClasses', function () {
        removeColorfulClasses();
      });


    });

  }
}

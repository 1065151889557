import { Component, OnInit } from '@angular/core';

import { ChatService } from '../../models/chat/chat.service';
import { ChatDialog } from '../../models/chat/chat-dialog';

import { AuthService } from '../../models/Auth/auth.service';
import { environment } from 'environments/environment';


declare var $: any;
declare var responsiveVoice: any;
const speechRecognition = $(window).get(0).webkitSpeechRecognition;

@Component({
  selector: 'cat-top-bar',
  templateUrl: './top-bar.component-test.html',
  styleUrls: ['./top-bar.css'],
})


export class TopBarComponent implements OnInit {

  public APP_NAME = environment.SCHOOL_NAME

  constructor(private authService: AuthService, public chatService: ChatService) {

  }

  recognition;
  isMute = false;
  isHiSaid = false;
  responsePost: ChatDialog;
  userMessage: string="";
  responseSara: string;
  htmlcontentuser = [{ msg: this.userMessage, frm: "User" }];
  conversations: any = [];
  seesiomID = "outside"

  content = "contenido"
  image = "image"
  title = "Titulo"
  feel = "def"
  isContent = false;

  public MAIN_SCHOOL_BLACK_LOGO: any = environment.MAIN_SCHOOL_BLACK_LOGO;
  public SARA_DYNAMIC_IMAGES: any = (img:string = '') => environment.SARA_DYNAMIC_IMAGES(img)
  public SCHOOL_NAME: any = environment.SCHOOL_NAME;

  hisara() {

    if (!this.isHiSaid) {
      this.responsePost = { message: "tok " + this.authService.getToken(), langCode: "es", sessionId: this.seesiomID }
      this.chatService.askSara(this.responsePost).subscribe(p => {

        this.responseSara = p.message;

        this.conversations.push({ bot: this.responseSara, user: "Inicia sesión", feel: "def" })

        this.userMessage = '';
        console.log(this.conversations);

      });
      this.isHiSaid = true;
    }

  }

  recordMessage() {
    if (this.recognition) {
      this.stopRecognition(this);
    } else {
      this.startRecognition(this);
    }
  }

  startRecognition(ctx) {
    ctx.recognition = new speechRecognition();

    ctx.recognition.onresult = function (event) {
      var text = "";
      for (var i = event.resultIndex; i < event.results.length; ++i) {
        text += event.results[i][0].transcript;
      }
      ctx.userMessage = text
      ctx.sendMessage();
      ctx.stopRecognition(ctx);
    };

    this.recognition.onend = function () {
      ctx.stopRecognition(ctx);
    };

    ctx.recognition.lang = "es-ES";
    ctx.recognition.start();
  }

  stopRecognition(ctx) {
    if (ctx.recognition) {
      ctx.recognition.stop();
      ctx.recognition = null;
    }

  }


  sendMessage() {

    this.responsePost = { message: this.userMessage, langCode: "es", sessionId: this.seesiomID }
    this.chatService.askSara(this.responsePost).subscribe(p => {

      this.responseSara = p.message;
      this.feel = "def"
      this.isContent = false
      //Verificar Payload: 
      if (!!p.payload) {
        var payload = p.payload;

        if (payload.contenido) {
          this.isContent = true;
          this.content = payload.contenido
          this.image = payload.imagen
          this.title = payload.titulo
          //TODO: cargar post
          console.log("contenido")
        }
        else {
          var feeling = payload.sentimiento
          //TODO: cargar animacion
          console.log("sentimiento")
          this.feel = feeling
        }
      }


      if (!this.isMute) {
        responsiveVoice.speak(this.responseSara, "Spanish Latin American Female")
      }

      this.updateScroll()

      this.conversations.push({ bot: this.responseSara, user: this.userMessage, feel: this.feel })

      this.userMessage = '';
      console.log(this.conversations);

    });

  }

  stopVoice() {
    if (this.isMute) {
      this.isMute = false;


    } else {
      this.isMute = true;
      responsiveVoice.cancel();
    }

  }

  updateScroll() {
    $(function () {
      var height = 0;
      $('.cont-body-conver .chat').each(function (i, value) {
        height += parseInt($(this).height());
      });

      height += 0;

      $('.cont-body-conver').animate({ scrollTop: height });
    })
  }

  ngOnInit() {
  this.seesiomID =  this.authService.getToken() ? this.authService.getToken().substring(1, 10) : "rnd";

    $(function () {

      //Show-Hide
      $("#hide").click(function () {
        $(".cont-chat-sara").hide(900);
        $("#show").show(900);
      });
      $("#hide2").click(function () {
        $(".cont-chat-sara").hide(900);
        $("#show").show(900);
      });
      $("#hide3").click(function () {
        $(".cont-chat-sara").hide(900);
        $("#show").show(900);
      });
      $("#show").click(function () {
        $(".cont-chat-sara").show(900);
        $("#show").hide(900);
      });

    });

  }

}

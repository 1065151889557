

//Here we will store all the variables required by the components.
//If we want, we can initialize them with default values

interface SchoolToLoadOptions {
  school?: string;

  //#region SCHOOL LOADER
  loader?: string;
  bg?: string;
  tank?: string;
  cloud?: string;
  henoBall?: string;
  bgDesert?: string;
  //#endregion

  mainSchoolBlackLogo?: string;
  mainSchoolWhiteLogo?: string;
  saraFeelingDefault?: string;
}


//Default storages
const storage = {
  cedoc: 'https://storage.googleapis.com/assets_cedoc',
  celic: 'https://storage.googleapis.com/celic_assets',
}

/**
 * Default school variables
 * @param school 
 * @returns 
 */
export const schoolToLoad = (
  {
    school = 'cedoc',

    //#region SCHOOL LOADER
    loader = 'loader',
    bg = 'sign',
    tank = 'car',
    cloud = 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/43033/poof.svg',
    henoBall = 'heno-ball-404',
    bgDesert = 'bg-desert-404',
    //#endregion

    mainSchoolWhiteLogo = 'logo_Cedoc',
    mainSchoolBlackLogo = 'logo',

    saraFeelingDefault = 'user-default',
    
  }: SchoolToLoadOptions = {}) => {
  return {
    SCHOOL_LOADER: {
      LOADER: `${storage[school]}/modules/pages/common/img/${loader}.png`,
      BG: `${storage[school]}/modules/pages/common/img/${bg}.png`,
      TANK: `${storage[school]}/modules/pages/common/img/${tank}.png`,
      CLOUD: cloud,
      HENO_BALL: `${storage[school]}/modules/pages/common/img/${henoBall}.png`,
      DESERT_BG: `${storage[school]}/modules/pages/common/img/${bgDesert}.png`
    },

    MAIN_SCHOOL_WHITE_LOGO: `${storage[school]}/modules/pages/common/img/${mainSchoolWhiteLogo}.png`,
    MAIN_SCHOOL_BLACK_LOGO: `${storage[school]}/modules/pages/common/img/${mainSchoolBlackLogo}.png`,
    SARA_FEELING_DEFAULT: `${storage[school]}/modules/pages/common/img/Sara/feelings/${saraFeelingDefault}.png`,

    SARA_DYNAMIC_IMAGES: (img:string = '') => `${storage[school]}/modules/pages/common/img/Sara/${img}.png`,
    SARA_FEELING_DYNAMIC: (feel: any) => `${storage[school]}/modules/pages/common/img/Sara/feelings/sara-${feel}.gif`,

    SCHOOL_NAME: school.toUpperCase()
  }
}







import { Injectable } from '@angular/core';


import { Router } from '@angular/router';


import { UsersService } from '../usersInfo/users.service';
import { AuthService } from '../Auth/auth.service';
import { ChatDialog } from './chat-dialog';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';


@Injectable()


export class ChatService {
  

  constructor(private http: HttpClient, private router: Router, private userService: UsersService, private authService: AuthService) { }

  
  private baseUrl = `${environment.urlDialogFlowCloudFunction}dialogflow-client`;

  
  putHeaders() : HttpHeaders {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return headers;
  }

  askSara(chatDialog: ChatDialog): Observable<any> {

    let responsePost = this
      .http
      .post(`${this.baseUrl}/query`,
        JSON.stringify(chatDialog),
        { headers: this.putHeaders() })

    return responsePost;

  }

}
